import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { InvertButton, PrimaryButton } from 'component-library';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../../contexts/AppContext';
import { Oem, OemIndirect } from '../../models/ApiModels';
import { GetOem, GetOems, GetOemsIndirect, UpdateOemAsync } from '../../services/OemService';
import { ActionType } from '../../store/actionTypes';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
import PermissionsGate, { ROLES } from '../../utils/PermissionGate';
import FunctionPackagesPrices from '../IndirectCustomer/FunctionPackagesPrices';

export const SettingsPrices = () => {
  const dispatch = useDispatch();
  const { currentOemTenantId, isHumphreeUser, setCurrentOem, isBusinessCustomer, currentOem } = useContext(AppContext);
  const oems = useSelector((state: any) => state.oems.data);
  const storeIndirectOem = oems.find((oem: any) => oem.id === currentOemTenantId);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const isBusinessUser = currentOem?.parentOEMTenantId === null;
  const [indirectOem, setIndirectOem] = useState<OemIndirect>(storeIndirectOem);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setIndirectOem((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlePriceChange = (index: number, newPrice: number) => {
    setIndirectOem((prev) => {
      const updatedPriceList = [...prev.settings.priceList];
      updatedPriceList[index] = { ...updatedPriceList[index], price: newPrice };
      return {
        ...prev,
        settings: {
          ...prev.settings,
          priceList: updatedPriceList,
        },
      };
    });
  };

  useEffect(() => {
    if (_.isEqual(indirectOem, storeIndirectOem)) setHasChanges(false);
    else {
      setHasChanges(true);
    }
  }, [indirectOem, storeIndirectOem]);

  function UpdateOem() {
    setLoading(true);
    if (indirectOem) {
      UpdateOemAsync(indirectOem as any)
        .then(() => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Successfully updated prices!', status: 'success' },
          });
          if (isHumphreeUser) {
            GetOems()
              .then((oems: Oem[]) => {
                dispatch({
                  type: ActionType.ADD_OEMS,
                  payload: oems,
                });
              })
              .catch((e: any) => {
                console.log(e);
              });
          } else {
            GetOem(currentOemTenantId)
              .then((res: Oem) => {
                if (res.parentOEMTenantId === null) {
                  GetOemsIndirect(currentOemTenantId)
                    .then((oems: OemIndirect[]) => {
                      dispatch({
                        type: ActionType.ADD_OEMS,
                        payload: oems,
                      });
                    })
                    .catch((e: any) => {
                      console.log(e);
                    });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .finally(() => {
          setEditMode(false);
          setLoading(false);
        });
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div>
          <PermissionsGate roles={[ROLES.admin, ROLES.editor]} allowOemUsers rejected={undefined}>
            {(isBusinessUser || isHumphreeUser) && (
              <>
                <div className='w-full lg:ml-auto lg:w-fit flex flex-col gap-2 flex-wrap md:flex-nowrap md:flex-row lg:sticky top-0 right-0 z-10 my-6 lg:mt-11 lg:-mb-11 xl:mb-12 xl:-mt-12'>
                  {!editMode ? (
                    <PrimaryButton
                      fullWidth
                      icon={faPenToSquare}
                      label={'Edit prices'}
                      onClick={() => setEditMode(!editMode)}
                    />
                  ) : (
                    <div className='flex gap-2 w-full'>
                      <InvertButton label={'Leave edit'} onClick={() => setEditMode(!editMode)} />
                      <PrimaryButton label={'Save prices'} onClick={() => UpdateOem()} disabled={!hasChanges} />
                    </div>
                  )}
                </div>
              </>
            )}
          </PermissionsGate>
          <div className='flex pt-16 justify-between'>
            <div className='flex flex-col'>
              <h4 className='prose-heading4 pb-2'>Upgrade prices</h4>
              <p className='prose-paragraphBase text-gray-60 max-w-[52ch] mb-3'>
                Input prices and currency displayed for this customer when purchasing function package upgrades from the
                portal.
                <br />
                <br />
                If a customer upgrades from one package to another, the difference will be displayed.
              </p>
            </div>
          </div>
          <FunctionPackagesPrices
            indirectOem={indirectOem}
            handlePriceChange={handlePriceChange}
            handleChange={handleChange}
            editMode={editMode}
          />
        </div>
      )}
    </>
  );
};
