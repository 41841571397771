import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { OemIndirect } from '../../models/ApiModels';

type InfoProps = {
  newIndirectOem?: OemIndirect;
  handleChange: (e: any) => void;
  handlePriceChange: (item: any, index: any) => void;
  indirectOem?: OemIndirect;
  editMode?: boolean;
};

function FunctionPackagesPrices({ newIndirectOem, handleChange, handlePriceChange, indirectOem, editMode }: InfoProps) {
  const packages = useSelector((state: any) => state.configurations.packages);
  const autoTrim = [true, true, true, true];
  const autoList = [true, true, true, true];
  const pitchControl = [true, true, true, true];
  const coordinatedTurn = [false, true, false, true];
  const rollStab = [false, false, true, true];
  const oem = newIndirectOem || indirectOem;

  return (
    <div>
      <div className='flex gap-2 items-center py-3 border-y border-gray-3 mb-4'>
        <span className='prose-paragraphBase'>Currency</span>
        <div className='ml-auto'>
          {newIndirectOem || editMode ? (
            <input
              className='bg-gray-3 px-3 h-10 prose-paragraphTiny'
              type='text'
              name='currencyCode'
              placeholder='Enter display currency...'
              value={oem?.currencyCode}
              onChange={handleChange}
              maxLength={3}
            />
          ) : (
            <div>{oem?.currencyCode}</div>
          )}
        </div>
      </div>
      <div className='grid grid-cols-9'>
        <div className='hidden xl:grid grid-cols-9 col-span-9 px-5 mb-2'>
          <span className='uppercase prose-labelTableColumn col-span-2'></span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center w-full'>
            Auto trim
          </span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center w-full'>
            Auto list
          </span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center w-full'>
            Pitch control
          </span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center w-full'>
            Coordinated turn
          </span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center w-full'>
            Roll stabilization
          </span>
        </div>
        <div className='hidden xl:flex bg-gray-5 py-2 xl:px-5 mb-1 prose-mainMenuL2Default col-span-9'>Manual</div>
        {oem?.settings.priceList?.map((item, index) => {
          return (
            <div
              className='xl:grid justify-center grid-cols-9 col-span-9 border-b border-gray-10 py-2 xl:py-6 xl:px-5 my-1 items-center gap-2'
              key={item.packageId}
            >
              <label className='col-span-2 prose-mainMenuL2Default'>
                {packages.find((pack: any) => pack.id === item.packageId)?.displayName || 'Underwater lights'}
              </label>
              <div className='hidden xl:grid justify-center col-span-1 text-center'>
                {autoTrim[index] === true && <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />}
              </div>
              <div className='hidden xl:grid justify-center col-span-1 text-center'>
                {autoList[index] === true && <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />}
              </div>
              <div className='hidden xl:grid justify-center col-span-1 text-center'>
                {pitchControl[index] === true && <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />}
              </div>
              <div className='hidden xl:grid justify-center col-span-1 text-center'>
                {coordinatedTurn[index] === true && <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />}
              </div>
              <div className='hidden xl:grid justify-center col-span-1 text-center'>
                {rollStab[index] === true && <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />}
              </div>
              <div className='col-span-2 flex gap-2 justify-between items-center w-full'>
                {newIndirectOem || editMode ? (
                  <input
                    className='w-full bg-gray-3 px-3 h-10 prose-paragraphTiny'
                    type='number'
                    placeholder='Enter price input...'
                    value={item.price || ''}
                    onChange={(e) => handlePriceChange(index, Number(e.target.value))}
                  />
                ) : (
                  <span className='w-full xl:px-3 h-10 flex items-center xl:justify-end font-bold'>{item.price}</span>
                )}
                <div className='font-bold prose-heading5'>{oem?.currencyCode}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FunctionPackagesPrices;
