import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvertButton, Popup, PrimaryButton } from 'component-library';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { Oem, OemIndirect } from '../../models/ApiModels';
import { AddOemIndirect, GetOemsIndirect } from '../../services/OemService';
import { ActionType } from '../../store/actionTypes';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
import { CreateIndirectCustomerInfo } from './CreateIndirectCustomerInfo';
import { CreateIndirectCustomerPricing } from './CreateIndirectCustomerPricing';

export const CreateIndirectCustomer = () => {
  const { currentOem, currentOemTenantId } = useContext(AppContext);
  const [hasChanges, setHasChanges] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const oems = useSelector((state: any) => state.oems.data);
  const selectedOem = oems.find((oem: Oem) => oem.id === currentOemTenantId);

  const initIndirectOem: OemIndirect = {
    id: '',
    name: '',
    currencyCode: 'SEK',
    parentOEMTenantId: currentOem?.id,
    settings: {
      invoiceStrategy: 'MonthlyInvoice',
      orderConfirmationEmail: '',
      priceList: [
        { packageId: 'PKG-1-1', price: 0 },
        { packageId: 'PKG-2-1', price: 0 },
        { packageId: 'PKG-3-1', price: 0 },
        { packageId: 'PKG-4-1', price: 0 },
        { packageId: 'FEAT-7-1', price: 0 },
      ],
    },
  };

  const [newIndirectOem, setNewIndirectOem] = useState<OemIndirect>(initIndirectOem);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewIndirectOem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePriceChange = (index: number, newPrice: number) => {
    setNewIndirectOem((prev) => {
      const updatedPriceList = [...prev.settings.priceList];
      updatedPriceList[index] = { ...updatedPriceList[index], price: newPrice };

      return {
        ...prev,
        settings: {
          ...prev.settings,
          priceList: updatedPriceList,
        },
      };
    });
  };

  useEffect(() => {
    if (_.isEqual(initIndirectOem, newIndirectOem)) setHasChanges(false);
    else {
      setHasChanges(true);
    }
  }, [newIndirectOem]);

  function GoBack() {
    if (hasChanges) {
      setShowPopup(true);
    } else {
      setTimeout(() => {
        navigate(-1);
      }, 1);
    }
  }

  const saveIndirectCustomer = useCallback((newIndirectOem: OemIndirect) => {
    setLoading(true);
    AddOemIndirect(newIndirectOem)
      .then(() => {
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: 'You created a new customer!', status: 'success' },
        });
        GetOemsIndirect(newIndirectOem.parentOEMTenantId || '')
          .then((oems: OemIndirect[]) => {
            dispatch({
              type: ActionType.ADD_OEMS,
              payload: oems,
            });
          })
          .catch(() => {
            console.log('Something went wrong while fetching indirect customers');
          });
        navigate('/');
      })
      .catch((error) => {
        console.log('Something went wrong while creating indirect customer', error);
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: error.message, status: 'error' },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //Check for string
  const validOemInput = useMemo(() => {
    return newIndirectOem.name !== '';
  }, [newIndirectOem.name]);

  //Check for valid
  const validCustomerCreate = useMemo(() => {
    return validOemInput;
  }, [newIndirectOem]);

  return (
    <>
      {loading && <LoadingSpinner text='Creating customer...' />}
      {!loading && (
        <div className='grid gap-12 w-full'>
          <div className='flex gap-2 prose-heading3 text-primary-400 items-center pb-16'>
            <span className='cursor-pointer' onClick={() => GoBack()}>
              {currentOem?.name || selectedOem?.name}
            </span>
            <FontAwesomeIcon icon={faChevronRight} size='sm' />
            <span>Create new customer</span>
          </div>
          <CreateIndirectCustomerInfo newIndirectOem={newIndirectOem} handleChange={handleChange} />
          <CreateIndirectCustomerPricing
            newIndirectOem={newIndirectOem}
            handleChange={handleChange}
            handlePriceChange={handlePriceChange}
          />
          <div className='flex gap-2 justify-end pt-10 flex-wrap'>
            <InvertButton
              label='Cancel'
              disabled={loading}
              onClick={() => {
                if (hasChanges) setShowPopup(true);
                else
                  setTimeout(() => {
                    navigate('/');
                  }, 1);
              }}
            />
            <PrimaryButton
              label='Create customer'
              disabled={!validCustomerCreate || loading}
              onClick={() => {
                setNewIndirectOem({ ...newIndirectOem });
                saveIndirectCustomer(newIndirectOem);
              }}
            />
          </div>
          {showPopup && (
            <Popup
              close={() => setShowPopup(false)}
              confirm={() =>
                setTimeout(() => {
                  navigate('/');
                }, 1)
              }
              heading='You have unsaved changes.'
              paragraph="Do you want to discard any changes you've made?"
              status='error'
              cancelText='Go back'
              confirmText='Discard'
            />
          )}
        </div>
      )}
    </>
  );
};
